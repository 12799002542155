<template>
  <div class="applyProductCombo-container">
    <div class="header2 update_win">
      <van-nav-bar
        title="提报基本信息"
        left-text="返回列表"
        left-arrow
        @click-left="onClickLeft"
      ></van-nav-bar>
    </div>
    <div class="comboList1">
      <van-form validate-first class="updateForm_cs">
        <van-cell title="产品经理:" class="van-ellipsis" @click="show = true">
          <template #title>
            产品经理: <span style="color: red; font-size: 15px">*</span>
          </template>
          <div class="sk2">
            <span
              v-if="form.productUName"
              style="margin-right: 15px; color: black"
              >{{ form.productUName }}</span
            >
            <span v-else style="margin-right: 15px">请选择产品经理</span>
            <van-icon name="arrow" />
          </div>
        </van-cell>
        <van-action-sheet
          v-model="show"
          :actions="actions"
          cancel-text="取消"
          close-on-click-action
          @cancel="onCancel"
          :close-on-click-overlay="false"
          @select="onSelect"
        />
        <van-cell title="商品图片:" class="first_list addImg_cs">
          <template #title>
            商品图片: <span style="color: red; font-size: 15px">*</span>
          </template>
          <el-upload
            style="display: inline-block; padding-left: 18px"
            :limit="max"
            :action="
              $store.state.imgUpdateUrl +
              '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
            "
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :file-list="form.commodityImg"
            :on-success="
              (response) => {
                return productRowUpdateFlie2(response);
              }
            "
            :on-exceed="fnmax"
            :on-remove="
              (file, fileList) => {
                return productHandleRemove1(file, fileList);
              }
            "
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </van-cell>
        <van-cell-group>
          <van-cell title="商品名称:" class="van-ellipsis">
            <template #title>
              商品名称: <span style="color: red; font-size: 15px">*</span>
            </template>
            <van-field
              v-model.trim="form.original"
              v-emoji
              @input="getEmoji(form.original)"
              maxlength="30"
              placeholder="请输入商品名称"
            />
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="卖点:" class="van-ellipsis titleImg_cs">
            <template #title>
              卖点: <span style="color: red; font-size: 15px">*</span>
            </template>
            <van-field
              v-model.trim="form.sellingData"
              rows="2"
              autosize
              v-emoji
              type="textarea"
              @input="getEmoji1(form.sellingData)"
              maxlength="50"
              placeholder="请输入卖点"
              show-word-limit
            />
          </van-cell>
        </van-cell-group>

        <van-cell title="产品类目:" class="van-ellipsis" @click="show1 = true">
          <template #title>
            产品类目: <span style="color: red; font-size: 15px">*</span>
          </template>
          <div class="sk2">
            <span
              v-if="form.productVal"
              style="margin-right: 15px; color: black"
              >{{ form.productVal }}</span
            >
            <span v-else style="margin-right: 15px">请选择产品类目</span>
            <van-icon name="arrow" />
          </div>
        </van-cell>
        <van-action-sheet
          v-model="show1"
          :actions="productClass"
          cancel-text="取消"
          close-on-click-action
          @cancel="onCancel"
          :close-on-click-overlay="false"
          @select="showProduct"
        />
        <van-cell title="是否外输:" class="van-ellipsis" @click="show2 = true">
          <template #title>
            是否外输: <span style="color: red; font-size: 15px">*</span>
          </template>
          <div class="sk2">
            <span
              v-if="form.isExport"
              style="margin-right: 15px; color: black"
              >{{ form.isExport }}</span
            >
            <span v-else style="margin-right: 15px">请选择是否外输</span>
            <van-icon name="arrow" />
          </div>
        </van-cell>
        <van-action-sheet
          v-model="show2"
          :actions="$store.state.isExport"
          cancel-text="取消"
          close-on-click-action
          @cancel="onCancel"
          :close-on-click-overlay="false"
          @select="isCarriage"
        />
        <van-cell-group v-if="setDisabted" class="checkBox_cs">
          <van-cell title="需要外输渠道:">
            <template #title>
              外输渠道: <span style="color: red; font-size: 15px">*</span>
            </template>
            <template>
              <p
                v-for="(item, index) in form.exportList"
                for="boxChecked"
                class="ceckedCell_cs"
                :key="index.id"
              >
                &nbsp;&nbsp;&nbsp;&nbsp;<van-checkbox
                  v-model="form.box_checked[index + 1]"
                  label-position="left"
                  :id="'checkbox' + { index }"
                  shape="square"
                  >{{ item.name }}</van-checkbox
                >
              </p>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group v-else> </van-cell-group>
        <div class="comboBtn"></div>
      </van-form>
      <van-tabbar>
        <div style="width: 100%; text-align: right">
          <van-button
            type="primary"
            @click="setSubmit"
            style="margin: 5px; border-radius: 5px"
            >下&nbsp;&nbsp;一&nbsp;&nbsp;步</van-button
          >
        </div>
      </van-tabbar>
    </div>
  </div>
</template>
<script>
import { Empty, Notify } from "vant";
export default {
  data() {
    return {
      imgType: ['image/jpg','image/jpeg','image/png','image/gif'],
      show: false,
      max: 8,
      actions: [],
      show1: false,
      show2: false,
      productClass: [],
      setDisabted: false,
      // 提交数据
      form: {
        sellingData: "", //卖点
        productVal: "",
        productUName: "",
        isExport: "不确定",
        commodityImg: [],
        original: "",
        box_checked: [],
        exportList: [],
        UID: "", //扫码进入时获取的产品经理id
      },
      staticList: {
        productUList: [], //产品经理数据
        productClass: [], //产品类目
      },
    };
  },
  created() {
    this.getManager();
    this.productType();
    this.getexportList();
  },
  methods: {
    // 获取外输渠道
    getexportList() {
      this.$http
        .ajax("/ffkj-main/merchantProduct/outputTypeList", {}, "get", {})
        .then((res) => {
          if (res.code == 0) {
            this.form.exportList = res.data;
          } else {
            Notify({ type: "danger", message: res.message });
          }
        });
    },
    // 不通过扫码进入默认的产品经理
    getManager() {
      if (localStorage.getItem("UID") != "null") {
        this.getUid();
      } else {
        this.$http
          .ajax(
            "/ffkj-main/merchantManager/listManager",
            {
              merchantId: localStorage.getItem("merchantId"),
            },
            "get",
            {}
          )
          .then((res) => {
            if (res.code == 0) {
              this.staticList.productUList = res.data;
              for (var i = 0; i < res.data.length; i++) {
                this.actions.push({ name: res.data[i].name });
              }
            } else {
              Notify({ type: "danger", message: res.message });
            }
          });
      }
    },
    getEmoji(val) {
      let iconRule2 =
        /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi;
      if (iconRule2.test(val)) {
        this.form.original = this.form.original.replace(iconRule2, "");
        Notify({ type: "danger", message: "内容不能包含表情!!!" });
        return false;
      }
    },
    getEmoji1(val) {
      let iconRule2 =
        /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi;
      if (iconRule2.test(val)) {
        this.form.sellingData = this.form.sellingData.replace(iconRule2, "");
        Notify({ type: "danger", message: "内容不能包含表情!!!" });
        return false;
      }
    },
    onSelect(action) {
      this.form.productUName = action.name;
    },
    showProduct(action) {
      this.form.productVal = action.name;
    },
    isCarriage(action) {
      this.form.isExport = action.name;
      if (action.name == "不允许" || action.name == "不确定") {
        this.form.box_checked = [];
        this.setDisabted = false;
      } else {
        this.setDisabted = true;
      }
    },
    // 上传图片格式效验
    beforeAvatarUpload(file) {
        if ( this.imgType.indexOf(file.type) == -1 ) {
            Notify({ type: "danger", message: "上传图片只能是JPG/JPEG/PNG/GIF格式" });
            return false
        }
        if ( file.size / 1024 / 1024 > 50 ) {
            Notify({ type: "danger", message: "上传图片大小不能超过50MB" });
            return false
        }
    },
    // 返回列表
    onClickLeft() {
      // localStorage.removeItem('UID')
      window.localStorage.setItem("UID", null);
      this.$router.push({
        path: "/productAudit",
        query: {},
      });
    },
    // 取消
    onCancel() {},
    // 扫码进入时获取产品经理
    getUid() {
      // 获取产品经理
      if (localStorage.getItem("UID") != "null") {
        this.$http
          .ajax(
            "/ffkj-main/sysUser/sname?cid=" + localStorage.getItem("UID"),
            {},
            "get",
            {}
          )
          .then((res) => {
            if (res.code == 0) {
              this.form.UID = res.data.id;
              this.form.productUName = res.data.name;
            } else {
              Notify({ type: "danger", message: "没有获取到该产品经理" });
              if (res.message == "用户不存在") {
                window.localStorage.setItem("UID", null);
                this.getManager();
              }
            }
          });
      } else {
      }
    },
    // 产品类目下拉列表
    productType() {
      this.$http
        .ajax("/ffkj-main/commodityContractType/list", {}, "get", {})
        .then((res) => {
          if (res.code == 0) {
            this.staticList.productClass = res.data;
            for (var i = 0; i < res.data.length; i++) {
              this.productClass.push({
                name: res.data[i].commodityContractTypeName,
              });
            }
          } else {
            Notify({ type: "danger", message: res.message });
          }
        });
    },
    // 最多上传数量
    fnmax(el) {
      Notify({ type: "danger", message: "最多允许上传" + this.max + "张" });
    },
    // 上传商品图片
    productRowUpdateFlie2(response) {
      this.form.commodityImg.push({ url: response });
      if (this.form.commodityImg.length >= this.max) {
        Notify({ type: "warning", message: "图片上传已达上限!!!" });
      }
    },
    // 删除商品图片
    productHandleRemove1(file, fileList) {
      this.form.commodityImg = fileList;
    },
    // 提交
    setSubmit() {
      if (this.form.productUName == "") {
        Notify({ type: "danger", message: "请选择产品经理!!!" });
        return false;
      }
      if (this.form.commodityImg.length <= 0) {
        Notify({ type: "danger", message: "请上传商品图片!!!" });
        return false;
      }
      if (!this.form.original || this.form.original.trim().length <= 0) {
        Notify({ type: "danger", message: "请输入商品名称!!!" });
        return false;
      }
      if (this.form.productVal == "") {
        Notify({ type: "danger", message: "请选择产品类目!!!" });
        return false;
      }
      if (this.form.sellingData == "") {
        Notify({ type: "danger", message: "卖点不能为空!!!" });
        return false;
      }

      let obj = {
        managerID: "", //产品经理id
        checkedId: "", //是否外输id
        contractTypeId: "", //产品类目id
        productImg: "", //商品图片
        isExportId: "", //是否外输id
      };
      if (localStorage.getItem("UID") == "null") {
        for (let i = 0; i < this.staticList.productUList.length; i++) {
          if (this.form.productUName == this.staticList.productUList[i].name) {
            obj.managerID = this.staticList.productUList[i].id;
          }
        }
      }
      for (let i = 1; i < this.form.box_checked.length; i++) {
        if (this.form.box_checked[i]) {
          obj.checkedId += i + ",";
        }
      }
      for (let i = 0; i < this.staticList.productClass.length; i++) {
        if (
          this.form.productVal ==
          this.staticList.productClass[i].commodityContractTypeName
        ) {
          obj.contractTypeId = this.staticList.productClass[i].id;
        }
      }
      for (let i = 0; i < this.form.commodityImg.length; i++) {
        obj.productImg += this.form.commodityImg[i].url + ",";
      }
      obj.isExportId =
        this.form.isExport == "允许"
          ? 1
          : this.form.isExport == "不允许"
          ? 2
          : this.form.isExport == "不确定"
          ? 3
          : this.form.isExport;
      this.$http
        .ajax(
          "/ffkj-main/merchantProduct/create",
          {
            productImg: obj.productImg, //提交图片
            productName: this.form.original, //产品名称
            commodityContractTypeId: obj.contractTypeId, //产品类目id
            output: obj.isExportId, //是否外输id
            outputType: obj.checkedId, //外输id
            merchantId: localStorage.getItem("merchantId"), //商家id
            sysUserId: obj.managerID == "" ? this.form.UID : obj.managerID, //产品经理id
            description: this.form.sellingData,
          },
          "post",
          {}
        )
        .then((res) => {
          if (res.code == 0) {
            window.localStorage.setItem("UID", null);
            Notify({ type: "success", message: "创建成功" });
            setTimeout(
              function () {
                this.$router.push({
                  path: "/applyProductCombo",
                  query: {
                    ids: res.data,
                  },
                });
              }.bind(this),
              500
            );
          } else {
            Notify({ type: "danger", message: res.message });
          }
        });
    },
  },
};
</script>
<style scoped></style>
